<template>
  <div class="KCmodule">
    <Header title="附件管理" index="首页" titleOne="工程专利" beforeTitle="附件管理" />
    <div class="content">
      <div class="topUsers" style="margin-top: 24px;">
        <div>
          <el-select
            clearable
            v-model="patentParams.patent_type"
            placeholder="请选择专利类型"
            @change="changePatentType"
          >
            <el-option label="发明专利" value="1">发明专利</el-option>
            <el-option label="实用新型" value="2">实用新型</el-option>
            <el-option label="外观设计" value="3">外观设计</el-option>
            <el-option label="植物新品种" value="4">植物新品种</el-option>
            <el-option label="国家新药" value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
          </el-select>
          <el-input
            clearable
            style="width: 224px;"
            v-model="patentParams.company_name"
            placeholder="请输入企业名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            style="width: 224px;"
            v-model="patentParams.patent_name"
            placeholder="请输入专利名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="upload" @click="handleAnnex()">附件快传</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="Loading···"
        :data="annexList.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_type" label="专利类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_type ? scope.row.patent_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_name" label="专利名" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="app_code" label="专利号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="附件名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.name ? scope.row.name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="150px" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="downloadFile(scope.row.url)">下载</el-button>
            <el-button class="del" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="annexList.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="annexList.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="annexList.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="annexList.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import site from '../../components/global.vue'
export default {
  data () {
    return {
      beforUrl: site.userSite,
      loading: false,
      patentParams: {
        page: 1,
        limit: 10,
      },
      annexList: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取列表
    getList () {
      this.axios.get('/api/project_patent/file_list', { params: { ...this.patentParams } }).then((res) => {
        if (res) {
          this.annexList = res.data
        }
      })
    },
    handleDel (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/project_patent/delete_file", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          })
            .catch((err) => {
              this.$message.error(err.message)
            });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //附件下载
    downloadFile (url) {
      // window.location.href = this.beforUrl + url
      window.open(this.beforUrl + url)
    },
    // 查询按钮
    handleSearch () {
      this.loading = true
      this.axios.get('/api/project_patent/file_list', { params: { ...this.patentParams } }).then((res) => {
        this.loading = false
        if (res) {
          this.annexList = res.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 附件快传按钮
    handleAnnex () {
      this.$router.push({ path: '/patent/annex_quick', query: {} })
    },
    // 专利类型下拉查询框内容改变事件
    changePatentType () {
      this.axios.get('/api/project_patent/file_list', { params: { ...this.patentParams } }).then((res) => {
        if (res) {
          this.annexList = res.data
        }
      })
    },
    handleSizeChange (val) {
      this.loading = true
      this.patentParams.limit = val
      this.axios.get('/api/project_patent/file_list', { params: { ...this.patentParams } }).then((res) => {
        this.loading = false
        if (res) {
          this.annexList = res.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      this.patentParams.page = val
      this.axios.get('/api/project_patent/file_list', { params: { ...this.patentParams } }).then((res) => {
        this.loading = false
        if (res) {
          this.annexList = res.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
  </script>
  
<style scoped>
.upload {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}
</style>